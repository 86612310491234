import React, { useEffect, useRef, useState } from "react";
/** @jsx jsx */
import { jsx, Box, useThemeUI } from "theme-ui";
import { Parallax } from "../../components/animate";
import {
  Flex,
  Text,
  Heading,
  FlexGrid,
  Container,
  AspectRatio,
  Avatar,
  Button,
} from "../../components/core";
import { motion } from "framer-motion";
import ImageWithOverlay from "../../components/ImageWithOverlay";
import { InView } from "react-intersection-observer";
import useWindowResize from "../../hooks/useWindowResize";
import Page from "../../components/Page";
import Contact from "../../components/Contact";
import { BackgroundVague } from "../../components/svg";
import { graphql } from "gatsby";
import { alpha } from "@theme-ui/color";
import { useResponsiveValue } from "../../hooks/breakpoints";
import SEO from "../../components/SEO";
import { getImage } from "gatsby-plugin-image";
import { ReactMarkdown } from "../../components/ReactMarkdown";

const QuiSommesNous = ({ data }) => {
  const { theme } = useThemeUI();
  const containerRef = useRef(null);
  const { width } = useWindowResize();
  const [leftContainer, setLeftContainer] = useState(0);
  const [currentTeamTab, setCurrentTeamTab] = useState(0);
  const isTabActive = (activeTab) => activeTab === currentTeamTab;
  const frontmatter = data.markdownRemark.frontmatter;

  useEffect(() => {
    if (containerRef.current) {
      setLeftContainer(containerRef.current.getBoundingClientRect().left + 16);
    }
  }, [width]);

  const value = useResponsiveValue([12, 6, 4]);
  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: (i % (12 / value)) * 0.2,
      },
    }),
    hidden: { opacity: 0 },
  };

  return (
    <Page>
      <SEO
        title=" Qui sommes-nous ?"
        description="Association marseillaise créée en 2017, Clean my Calanques milite pour la préservation de l'environnement et a pour ambition de sensibiliser l'ensemble de la population aux enjeux environnementaux."
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête association"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" variant="h1" color="white">
                Qui sommes-nous ?
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Container
        ref={containerRef}
        sx={{ paddingTop: [8, null, 14], paddingBottom: 4 }}
      >
        <Box>
          <Heading as="h2" color="primary" preLine sx={{ marginBottom: 2 }}>
            <ReactMarkdown
              components={{
                br: () => <></>,
              }}
              skipHtml
              withParagraphe={false}
            >
              {frontmatter.intro.title}
            </ReactMarkdown>
          </Heading>
          <Text
            variant="h4"
            preLine
            sx={{
              paddingTop: 6,
              backgroundImage: "url(/img/quote.svg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: ["180px", "200px", "224px"],
            }}
          >
            {frontmatter.intro.text}
          </Text>
          <Text sx={{ marginTop: 5 }}>{frontmatter.intro.author}</Text>
        </Box>
      </Container>
      <BackgroundVague
        width="100%"
        height={null}
        color="background3"
        sx={{
          bottom: "-1px",
          transform: "rotate(180deg)",
        }}
      />
      <Box
        as="section"
        sx={{
          backgroundColor: "background3",
          paddingTop: [4, 6, 8],
          paddingBottom: 8,
        }}
      >
        <Container>
          <Heading
            as="h3"
            variant="h1"
            color="secondary"
            sx={{ marginBottom: [5, null, 8] }}
          >
            Notre histoire
          </Heading>
          <FlexGrid
            columns={[12, null, 6]}
            direction={["column", null, "row"]}
            sx={{
              marginBottom: [8, null, 13],
            }}
            gutter={0}
          >
            <Box>
              <Text as="div">
                <ReactMarkdown>{frontmatter.histoire.part1.text}</ReactMarkdown>
              </Text>
            </Box>
            <Box
              sx={{
                paddingLeft: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image 1 notre histoire"
                    ref={ref}
                    isAnimated={inView}
                    overlayColor="background3"
                    image={getImage(frontmatter.histoire.part1.image)}
                    direction="right"
                  />
                )}
              </InView>
            </Box>
          </FlexGrid>
          <FlexGrid
            columns={[12, null, 6]}
            direction={["column-reverse", null, "row"]}
            gutter={0}
          >
            <Box
              sx={{
                paddingRight: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image 2 notre histoire"
                    ref={ref}
                    isAnimated={inView}
                    image={getImage(frontmatter.histoire.part2.image)}
                    overlayColor="background3"
                  />
                )}
              </InView>
            </Box>
            <Box>
              <Text as="div">
                <ReactMarkdown>{frontmatter.histoire.part2.text}</ReactMarkdown>
              </Text>
            </Box>
          </FlexGrid>
        </Container>
      </Box>
      <BackgroundVague width="100%" height={null} color="background3" />
      <Box
        as="section"
        sx={{
          position: "relative",
          paddingTop: [3, 0, null],
          paddingBottom: 8,
        }}
      >
        <Container>
          <Heading
            as="h3"
            variant="h1"
            color="secondary"
            sx={{ marginBottom: [5, null, 8] }}
          >
            Nos missions
          </Heading>
        </Container>
        <Box
          sx={{
            marginBottom: [8, null, 13],
            paddingLeft: theme.layout.container.px,
            paddingRight: [theme.layout.container.px, null, 0],
          }}
        >
          <FlexGrid columns={[12, null, 6]} direction={["column", null, "row"]}>
            <Box sx={{ paddingLeft: [0, null, leftContainer] }}>
              <Box>
                <Heading
                  as="h4"
                  variant="h2"
                  color="primary"
                  sx={{ marginBottom: 4 }}
                >
                  Nettoyer
                </Heading>
                <Text as="div">
                  <ReactMarkdown>
                    {frontmatter.missions.nettoyer.text}
                  </ReactMarkdown>
                </Text>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
                paddingTop: [0, null, 3],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image Nettoyer"
                    ref={ref}
                    isAnimated={inView}
                    image={getImage(frontmatter.missions.nettoyer.image)}
                    direction="right"
                  />
                )}
              </InView>
            </Box>
          </FlexGrid>
        </Box>
        <Box
          sx={{
            marginBottom: [8, null, 13],
            paddingRight: theme.layout.container.px,
            paddingLeft: [theme.layout.container.px, null, 0],
          }}
        >
          <FlexGrid
            columns={[12, null, 6]}
            direction={["column-reverse", null, "row"]}
            gutter={0}
          >
            <Box
              sx={{
                paddingRight: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image Sensibiliser"
                    ref={ref}
                    isAnimated={inView}
                    image={getImage(frontmatter.missions.sensibiliser.image)}
                  />
                )}
              </InView>
            </Box>
            <Box sx={{ paddingRight: [0, null, leftContainer] }}>
              <Heading
                as="h4"
                variant="h2"
                color="primary"
                sx={{ marginBottom: 4 }}
              >
                Sensibiliser
              </Heading>
              <Text as="div">
                <ReactMarkdown>
                  {frontmatter.missions.sensibiliser.text}
                </ReactMarkdown>
              </Text>
            </Box>
          </FlexGrid>
        </Box>
        <Box
          sx={{
            paddingLeft: theme.layout.container.px,
            paddingRight: [theme.layout.container.px, null, 0],
          }}
        >
          <FlexGrid columns={[12, null, 6]} direction={["column", null, "row"]}>
            <Box sx={{ paddingLeft: [0, null, leftContainer] }}>
              <Box>
                <Heading
                  as="h4"
                  variant="h2"
                  color="primary"
                  sx={{ marginBottom: 4 }}
                >
                  Partager
                </Heading>
                <Text as="div">
                  <ReactMarkdown>
                    {frontmatter.missions.partager.text}
                  </ReactMarkdown>
                </Text>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
                paddingTop: [0, null, 3],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image Partager"
                    ref={ref}
                    isAnimated={inView}
                    image={getImage(frontmatter.missions.partager.image)}
                    direction="right"
                  />
                )}
              </InView>
            </Box>
          </FlexGrid>
        </Box>
      </Box>
      <BackgroundVague
        width="100%"
        height={null}
        color="background3"
        sx={{
          bottom: "-1px",
          transform: "rotate(180deg)",
        }}
      />
      <Box
        as="section"
        sx={{
          paddingTop: [4, 7, 5],
          paddingBottom: 8,
          backgroundColor: "background3",
        }}
      >
        <Container>
          <Box sx={{ marginBottom: 8 }}>
            <Heading as="h3" variant="h1" color="secondary">
              Notre équipe
            </Heading>
            <Text as="div" fontSize="16px" sx={{ marginTop: 3 }}>
              <ReactMarkdown>{frontmatter.team.subtitle}</ReactMarkdown>
            </Text>
          </Box>
          <Flex
            gap={2}
            wrap="wrap"
            positions={["flex-start", "center"]}
            sx={{ marginBottom: 8 }}
          >
            {frontmatter.team.group_members.map(({ group }, i) => (
              <Button
                key={i}
                variant="badge"
                active={isTabActive(i)}
                outline
                onClick={() => {
                  setCurrentTeamTab(i);
                }}
              >
                {group.name}
              </Button>
            ))}
          </Flex>
          <FlexGrid
            positions={["center", "stretch"]}
            columns={[12, 6, 4]}
            gutterX={12}
            gutterY={8}
          >
            {frontmatter.team.group_members[currentTeamTab].group.members.map(
              ({ member }, i) => (
                <Box key={i}>
                  <InView triggerOnce threshold={0.5}>
                    {({ inView, ref, entry }) => (
                      <Member
                        ref={ref}
                        custom={i}
                        variants={variants}
                        animate={inView ? "visible" : "hidden"}
                        initial="hidden"
                        avatar={getImage(member.image)}
                        fullName={member.name}
                        role={member.role}
                      />
                    )}
                  </InView>
                </Box>
              )
            )}
          </FlexGrid>
        </Container>
      </Box>
      <Contact colorVague="background3" />
    </Page>
  );
};

const MotionBox = motion.custom(Box);

const Member = React.forwardRef(
  ({ avatar, fullName, role, sx, ...props }, ref) => (
    <MotionBox ref={ref} sx={{ textAlign: "center", ...sx }} {...props}>
      <Avatar
        alt={`Image membre ${fullName}, ${role}`}
        style={{ height: "176px", width: "176px", marginBottom: "30px" }}
        image={avatar}
      />
      <Heading as="h4" variant="h3" color="secondary">
        {fullName}
      </Heading>
      {role && (
        <Text
          color="primary"
          sx={{ fontWeight: "semiBold", marginTop: 1, lineHeight: "24px" }}
        >
          {role}
        </Text>
      )}
    </MotionBox>
  )
);

export default QuiSommesNous;

export const query = graphql`
  query QuiSommesNousQuery {
    markdownRemark(fields: { slug: { eq: "/qui-sommes-nous/" } }) {
      id
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        title
        intro {
          author
          text
          title
        }
        histoire {
          part1 {
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 760
                )
              }
            }
          }
          part2 {
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 760
                )
              }
            }
          }
        }
        missions {
          nettoyer {
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 1100
                )
              }
            }
          }
          partager {
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 1100
                )
              }
            }
          }
          sensibiliser {
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 1100
                )
              }
            }
          }
        }
        team {
          group_members {
            group {
              name
              members {
                member {
                  name
                  role
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: CONSTRAINED
                        width: 400
                      )
                    }
                  }
                }
              }
            }
          }
          subtitle
        }
      }
    }
  }
`;
